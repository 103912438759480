import React from "react";
import { graphql, Link } from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

class NotFoundPage extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="출시 예정..." />
        <div className="columns" style={{ padding: "10% 10% 5% 10%" }}>
          <div className="column" style={{ textAlign: "center" }}>
            <Link to="/">
              <img
                alt="404 Not Found"
                src="/img/grafika-404.png"
                style={{ minWidth: "250px" }}
                height="auto"
                width="auto"
              />
            </Link>
          </div>
          <div style={{ textAlign: "center" }}>
            <Link to="/">
              <h1
                className="title is-size-2"
                style={{ fontWeight: "bold", color: "#00ff00" }}
              >
                출시 예정...
              </h1>
            </Link>
            <h2 className="title">
              <strong>우리는 이것을 위해 노력하고 있습니다 ...</strong>
            </h2>
            <p className="is-size-5">시간을 주세요...</p>
            <br />
            <button
              className="button rexer-button is-size-4"
              onClick={() => {
                window.location.href = "/";
              }}
              style={{ marginBottom: "50px" }}
            >
              집으로 돌아가
            </button>
          </div>
        </div>
      </Layout>
    );
  }
}

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
